.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
  }

.badge-csharp {
    color: #fff;
    background-color: #178600;
  }
  .badge-python {
    color: #fff;
    background-color: #3572A5;
  }
  .badge-plsql {
    color: #000;
    background-color: #dad8d8;
  }
  .badge-javascript {
    color: #000;
    background-color: #f1e05a;
  }
  .badge-java {
    color: #fff;
    background-color: #b07219;
  }
  .badge-julia {
    color: #fff;
    background-color: #a270ba;
  }
  .badge-livescript {
    color: #fff;
    background-color: #499886;
  }
  .badge-cplusplus {
    color: #fff;
    background-color: #f34b7d;
  }
  .badge-ruby {
    color: #fff;
    background-color: #701516;
  }