@import "../fonts.css";

/* General component styling */
.container-fluid
{
    padding-left: 0rem;
    padding-right: 0rem;
    position: relative;
    overflow:hidden;
}

.container
{
  padding-left: 1rem;
  padding-right: 2rem;
  font-size: x-large;
  overflow:hidden;
  
}

body 
{
  font: 16px Montserrat, sans-serif;
  line-height: 1.8;
  color: #f5f6f7;
}

.img 
{
  border-radius: 50%;
}

hr { margin: 40px 0px; border-color: #727878; opacity: 0.2; filter:alpha(opacity=25); }

/* Custom division Styling */
.side-nav
{
  position:fixed;
  padding-left: 2%;
  padding-top: 15%;
  z-index: 30;
}
.side-nav ul {
  border-radius:20%;
}
.side-nav li {
  background-color:rgb(231, 219, 204);
  color: rgb(78, 76, 76);
}
.side-nav a {
  font: 16px Ubuntu;
  color: rgb(78, 76, 76);
}
.side-nav li:hover {
  background-color:rgb(0, 145, 145);
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}
.side-nav a:hover {
  color: rgb(255, 255, 255);
}


.div2
{
  padding-top: 70px;
  padding-bottom: 70px;
}

.tx-2
{
  color:rgb(78, 76, 76);
  font-size:18px;
}

.bg-2 
{ 
  background-color:rgb(231, 231, 231); /* Dark White */
  color:rgb(0, 145, 145);

}

.bg-4 
{ 
  background-color: rgb(190, 190, 190); /* Black Gray */
  color: rgb(0, 145, 145);
}

.bg-footer 
{ 
  background-color: rgb(0, 0, 0); /* Black Gray */
  color: rgb(255, 255, 255);

  
}

.tx-4
{
  color:rgb(78, 76, 76);
}

p {text-align: left;}

/* Divider line */
.outer 
{
  width: 1px;
  height: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.inner 
{
  position: absolute;
  width:100%;
  height: 40%;
  background: grey;
  top: 30%;
  box-shadow: 0px 0px 30px 20px grey;
}

/* Light Card animation and responsive handling */
.lcard
{
  border-radius: 4px;
  background: rgb(231, 219, 204);
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  padding: 14px 40px 18px 18px;
  cursor: pointer;
  color:rgb(78, 76, 76);
  text-align: left;
  max-width: 90vw;
  font-size: 16px;
  margin: 20px;
}

.lcard:hover
{
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

@media(max-width: 960px)
{
  .lcard
  {
    margin: 20px;
  }
} 

.lcard h4
{
  font-weight: 600;
}
.lcard h5
{
  font-weight: 400;
  font-style: italic;
}

.logo-container{
  width: 250px;
  height: 75px;
  text-align:center;
}

.logo-container-left{
  width: 250px;
  height: 75px;
  text-align:center;
}

.logo-container img {
  height:100%;
  width: 100%;
  padding: 5px;
  object-fit: contain;
}

.logo-container-left img {
  height:100%;
  width: 100%;
  padding: 5px;
  object-fit: contain;
}

/* Dark Card animation and responsive handling */
.dcard
{
  border-radius: 4px;
  background: rgb(231, 219, 204);
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  padding: 14px 40px 18px 18px;
  cursor: pointer;
  color:rgb(78, 76, 76);
  text-align: left;
  max-width: 90vw;
  font-size: 16px;

}

.dcard:hover
{
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

@media(max-width: 960px)
{
  .dcard
  {
    margin: 20px;
  }
} 

.dcard h4
{
  font-weight: 600;
}
.dcard h5
{
  font-weight: 400;
  font-style: italic;
}

.openChatBtn {
  cursor: pointer;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 53px;
  height:50px;
  z-index: 3;
  border-radius: 50%;
}

.openChatBtnHome {
  border: 0;
  padding:0;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  position: fixed;
  bottom: 23px;
  right: 128px;
  outline: none;
  border-radius: 50%;
}


.openBotBtn {
  border: 0;
  padding:0;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 80px;
  height:75px;
  z-index: 3;
  outline: none;
  border-radius: 50%;
}

.openBotBtnHome {
  border: 0;
  padding:0;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  position: fixed;
  bottom: 23px;
  right: 28px;
  z-index: 3;
  outline: none;
  border-radius: 50%;
}

@media(max-width: 960px)
{
  .openChatBtn {
    cursor: pointer;
    position: fixed;
    bottom: 23px;
    right: 28px;
    width: 53px;
    height:50px;
    z-index: 3;
    border-radius: 50%;
  }

  .openBotBtn {
    border: 0;
    padding:0;
    font-weight: 500;
    font-size: 18px;    
    cursor: pointer;    
    position: fixed;    
    bottom: 23px;
    right: 28px;
    width: 60px;
    height:55px;
    z-index: 3;
    outline: none;
    border-radius: 50%;
  }
} 

.modalVariant{
  color:rgb(0, 0, 0);
}


.form-popup {
  position: fixed;
  bottom: 0;
  right: 10px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #4CAF50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}